import React, { useState } from 'react'
import '../../utils/constants/CSS/eventForms.css'
import { useTranslate } from 'react-admin'
import { useFormikContext } from 'formik'
import { Divider, makeStyles, Switch, Typography } from '@material-ui/core'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { isFieldDisabled, isTeamLogoDisabled } from '../../utils/helpers/isFieldDisabled'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../utils/constants/gameEventFormBroadcastFields'
import eventFormLivestreamFieldsStyles from './eventFormLivestreamFields.styles'
import TextButton from '../ui/TextButton/TextButton'
import SelectRecentLivestreamDialog from './SelectRecentLivestreamDialog/SelectRecentLivestreamDialog'
import MySelectField from '../form/fields/MySelectField/MySelectField'
import QueryKeys from '../../utils/constants/queryKeys'
import getLivestreamTypes from '../../Apis/event/getLivestreamTypes'
import COLORS from '../../utils/constants/colors'
import MyMicIcon from '../../assets/icons/MyMicIcon'
import YoutubeStreamFields from '../YoutubeStreamFields/YoutubeStreamFields'
import LIVESTREAM_TYPE_OPTIONS from '../../utils/constants/livestreamTypeOptions'
import ExtraStreamField from '../YoutubeStreamFields/StreamFields/ExtraStreamField'
import SwitchTripleOption from '../SwitchTripleOption/SwitchTripleOption'
import { USER_SCOPES } from '../../utils/constants/userScopes'
import IsOrgAddonsAuthorized from '../IsOrgAddonsAuthorized/IsOrgAddonsAuthorized'
import ConditionalRender from '../ConditionalRender/ConditionalRender'

const EventFormLivestreamFields = ({
  state,
  supportsCommentary,
  allowOrgYoutube,
  broadcastId,
  selectedEventType,
}) => {
  const [isLivestreamModalOpen, setIsLivestreamModalOpen] = useState(false)
  const translate = useTranslate()
  const { values, setFieldValue } = useFormikContext()
  const useStyles = makeStyles(eventFormLivestreamFieldsStyles)
  const classes = useStyles()

  const toggleGroupColors = (buttonName) => {
    const buttonValue = values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_COMMENTARY_ON]
      ? 'Commentary'
      : 'Ambient'
    const isSelected = buttonValue === buttonName

    return {
      backgroundColor: isSelected ? COLORS.orange : undefined,
      color: isSelected ? COLORS.white : undefined,
      height: 40,
      width: '50%',
    }
  }

  const { data } = useQuery(
    [QueryKeys.GET_LIVESTREAM_TYPES],
    () => getLivestreamTypes({ eventTypeCode: selectedEventType?.id }),
    {
      staleTime: 0,
    },
  )
  const parsedLivestreamTypes = data?.map((datum) => ({
    id: datum.name,
    name: datum.displayName,
  }))

  // const setCroppedImage = (name, value) => setFieldValue(name, value)

  const handleCommentaryChange = (event, newValue) => {
    setFieldValue('isCommentaryOn', newValue === 'Commentary')
  }

  const isLivestreamTypeFull =
    (values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] ||
      values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]) &&
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE] === LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL

  return (
    <div className='div-flex-column'>
      <SwitchTripleOption
        fieldNames={[
          GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST,
          GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD,
        ]}
        optionLabels={[
          translate('ra.formFields.streamVideo'),
          translate('ra.formFields.recordVideo'),
        ]}
        disabled={isFieldDisabled(state)}
      />
      <div className='div-flex-space-between'>
        {(values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] ||
          values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]) && (
          <MySelectField
            name={GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE}
            label={translate('ra.formFields.livestreamType')}
            options={parsedLivestreamTypes}
            disabled={isFieldDisabled(state)}
          />
        )}

        {isLivestreamTypeFull && (
          <div>
            <TextButton
              className={classes.loadSetupsButton}
              label={translate('ra.buttons.loadRecentSetups')}
              onClick={() => setIsLivestreamModalOpen(true)}
              disabled={state ? isTeamLogoDisabled(state) : false}
            />
            <SelectRecentLivestreamDialog
              open={isLivestreamModalOpen}
              onClose={() => setIsLivestreamModalOpen(false)}
            />
          </div>
        )}
      </div>

      <ConditionalRender
        renderValue={
          (values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] ||
            values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]) &&
          selectedEventType.name === 'Game'
        }
      >
        <div className='div-flex'>
          <Typography>Auto-Start</Typography>
          <Switch
            name={GAME_EVENT_FORM_BROADCAST_FIELDS.AUTO_START}
            color='primary'
            checked={values[GAME_EVENT_FORM_BROADCAST_FIELDS.AUTO_START]}
            onChange={() =>
              setFieldValue(
                GAME_EVENT_FORM_BROADCAST_FIELDS.AUTO_START,
                !values[GAME_EVENT_FORM_BROADCAST_FIELDS.AUTO_START],
              )
            }
            disabled={isFieldDisabled(state)}
          />
        </div>
        <div className='div-flex'>
          <Typography>Auto-Stop</Typography>
          <Switch
            name={GAME_EVENT_FORM_BROADCAST_FIELDS.AUTO_STOP}
            color='primary'
            checked={values[GAME_EVENT_FORM_BROADCAST_FIELDS.AUTO_STOP]}
            onChange={() =>
              setFieldValue(
                GAME_EVENT_FORM_BROADCAST_FIELDS.AUTO_STOP,
                !values[GAME_EVENT_FORM_BROADCAST_FIELDS.AUTO_STOP],
              )
            }
          />
        </div>
      </ConditionalRender>

      {values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] && (
        <>
          <Divider className={classes.divider} />
          <YoutubeStreamFields
            state={state}
            allowOrgYoutube={allowOrgYoutube}
            broadcastId={broadcastId}
          />
        </>
      )}

      {values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] && <ExtraStreamField state={state} />}

      {isLivestreamTypeFull && (
        <>
          <ConditionalRender renderValue={values.hasStats}>
            <IsOrgAddonsAuthorized acceptedAddons={[USER_SCOPES.STATS]}>
              <div className='div-flex'>
                <Typography>{translate('ra.text.showCards')}:</Typography>
                <Switch
                  name='showCards'
                  color='primary'
                  checked={values.showCards}
                  onChange={() => setFieldValue('showCards', !values.showCards)}
                />
              </div>
            </IsOrgAddonsAuthorized>
          </ConditionalRender>
          <Divider className={classes.divider} />
          {values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] && supportsCommentary && (
            <div className='div-flex' style={{ justifyContent: 'center' }}>
              <MyMicIcon
                style={{
                  fill: COLORS.orange,
                  cursor: 'default',
                  height: 30,
                  width: 30,
                  marginLeft: '5%',
                }}
              />
              <ToggleButtonGroup
                name={GAME_EVENT_FORM_BROADCAST_FIELDS.IS_COMMENTARY_ON}
                exclusive
                value={
                  values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_COMMENTARY_ON]
                    ? 'Commentary'
                    : 'Ambient'
                }
                onChange={handleCommentaryChange}
                aria-label='Platform'
                className={classes.toggleGroup}
              >
                <ToggleButton style={toggleGroupColors('Ambient')} value='Ambient'>
                  {translate('ra.buttons.ambient')}
                </ToggleButton>
                <ToggleButton style={toggleGroupColors('Commentary')} value='Commentary'>
                  {translate('ra.buttons.commentary')}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
        </>
      )}
    </div>
  )
}

EventFormLivestreamFields.propTypes = {
  state: PropTypes.string,
  supportsCommentary: PropTypes.bool,
  allowOrgYoutube: PropTypes.bool,
  broadcastId: PropTypes.string,
}

export default EventFormLivestreamFields
