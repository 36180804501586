import { isBefore, isEqual } from 'date-fns'

const isTimeBefore = (d1, d2) => {
  if (!d1 || !d2) return false
  const now = new Date()

  const startDate = new Date(d1)
  const endDate = new Date(d2)

  // Set the same date to compare only time.
  startDate.setDate(now.getDate())
  startDate.setMonth(now.getMonth())
  startDate.setFullYear(now.getFullYear())

  endDate.setDate(now.getDate())
  endDate.setMonth(now.getMonth())
  endDate.setFullYear(now.getFullYear())

  if (isEqual(startDate, endDate)) return true
  return isBefore(startDate, endDate)
}

export default isTimeBefore
