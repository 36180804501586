import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import YouTubeIcon from '@material-ui/icons/YouTube'
import calendarEventClickModalStyles from './calendarEventClickModal.styles'
import { useTeamCalendarContext } from '../../../context/useTeamCalendarContext'
import RenderEventRange from '../../../components/renderEventRange/renderEventRange'
import BoxEventTypeColor from '../../../components/BoxEventTypeColor/BoxEventTypeColor'
import MyDeleteIcon from '../../../assets/icons/MyDeleteIcon'
import MyEditIcon from '../../../assets/icons/MyEditIcon'
import MyCloseIcon from '../../../assets/icons/MyCloseIcon'
import CalendarIcon from '../../../assets/icons/calendarIcon'
import DownloadIcon from '../../../assets/icons/downloadIcon'
import MyGroupIcon from '../../../assets/icons/myGroupIcon'
import useHistoryPush from '../../../hooks/useHistoryPush'
import BROADCAST_STATES from '../../../utils/constants/broadcastStates'
import DeleteModal from '../../../components/DeleteModal/DeleteModal'
import MyCopyIcon from '../../../assets/icons/MyCopyIcon'
import QueryKeys from '../../../utils/constants/queryKeys'
import BarChart from '@material-ui/icons/BarChart'
import getDownloadRecordingUrl from '../../../Apis/event/getDownloadRecordingUrl'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import PracticePlanInfoRow from '../../../components/PracticePlanInfoRow/PracticePlanInfoRow'
import IsUserScopesAuthorized from '../../../components/IsUserScopesAuthorized/IsUserScopesAuthorized'
import { USER_SCOPES } from '../../../utils/constants/userScopes'
import USER_ORG_ROLES from '../../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../../utils/constants/userOrgMembersRoles'
import IsUserRolesAuthorized from '../../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import COLORS from '../../../utils/constants/colors'
import TextButton from '../../../components/ui/TextButton/TextButton'
import PracticePlayerInfoRow from '../../../components/PracticePlayersInfoRow/PracticePlayerInfoRow'
import getGameStats from '../../../Apis/stats/getGameStats'
import isUserScopesValid from '../../../utils/helpers/isUserScopesValid'
import startLivestream from '../../../Apis/livestream/startLivestream'
import StartLivestreamConfirmationModal from './StartLivestreamConfirmationModal/StartLivestreamConfirmationModal'

const CalendarEventClickModal = ({ open, handleClose }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [copyRecordingClicked, setCopyRecordingClicked] = useState(false)
  const [showCopyAlert, setShowCopyAlert] = useState(false)
  const [preparationAlert, setPreparationAlert] = useState(false)
  const queryClient = useQueryClient()
  const {
    setSelectedEventType,
    event,
    deleteEvent,
    clickedEventId,
    setClickedEventId,
    coordinatesModal,
  } = useTeamCalendarContext()
  const {
    notes,
    hasRecording,
    type,
    title,
    teams,
    startDateTime,
    endDateTime,
    broadcast,
    ytBroadcastDetails,
    practicePlayers,
  } = event || {}
  const { broadcastUrl } = ytBroadcastDetails || {}
  const translate = useTranslate()
  const { redirect, pathname } = useHistoryPush()
  const useStyles = makeStyles(calendarEventClickModalStyles(coordinatesModal))
  const classes = useStyles()
  const isDeleteIconDisabled = broadcast?.state && broadcast?.state !== BROADCAST_STATES.scheduled
  const allowWatchVideo =
    broadcast?.id &&
    ((broadcast?.state === BROADCAST_STATES.finished && hasRecording) ||
      broadcast?.state === BROADCAST_STATES.running)
  const allowStartLive = broadcast?.state === BROADCAST_STATES.scheduled
  const [isStartLiveDisabled, setIsStartLiveDisabled] = useState(false)
  const [isStartLiveModalOpen, setIsStartLiveModalOpen] = useState(false)

  useEffect(() => {
    if (deleteEvent.isSuccess) {
      setOpenDeleteModal(false)
      setIsStartLiveDisabled(false)
      handleClose()
      setClickedEventId('')
    }
  }, [deleteEvent.isSuccess])

  useEffect(() => {
    let timeId = null
    let prepTimeId = null

    if (showCopyAlert) {
      timeId = setTimeout(() => {
        setShowCopyAlert(false)
        setCopyRecordingClicked(false)
      }, 5000)
    }
    if (preparationAlert) {
      prepTimeId = setTimeout(() => {
        setPreparationAlert(false)
      }, 5000)
    }

    return () => {
      if (timeId) {
        clearTimeout(timeId)
      }
      if (prepTimeId) {
        clearTimeout(prepTimeId)
      }
    }
  }, [showCopyAlert, preparationAlert])

  const { data: recording } = useQuery(
    [QueryKeys.GET_DOWNLOAD_RECORDING_URL],
    () => getDownloadRecordingUrl({ eventId: event?.id }),
    {
      enabled: !!hasRecording,
    },
  )

  const { data: gameStats, status } = useQuery(
    [QueryKeys.GET_GAME_STATS],
    () => getGameStats({ gameId: event?.id }),
    {
      enabled: Boolean(event?.id) && isUserScopesValid([USER_SCOPES.STATS]),
      retry: (failureCount, error) => {
        if (error?.response?.status === 400 || error?.response?.status === 404) {
          return false
        }
        return failureCount < 2
      },
    },
  )

  const startLivestreamMutation = useMutation((broadcastId) => startLivestream(broadcastId), {
    onSuccess: () => {
      setIsStartLiveDisabled(true)
      setIsStartLiveModalOpen(false)
    },
  })

  const constructSubtitle = () => {
    const splitText = translate('ra.text.deleteEventSubtitle').split('"".')

    if (type?.name === 'OTHER' || type?.name === 'LIVE_STREAM') {
      return splitText.map((text, index) => (
        <>
          {text}
          {index !== splitText.length - 1 && <b>{` ${title}.`}</b>}
        </>
      ))
    }

    return translate('ra.text.deleteEventSubtitle').replace('""', '')
  }

  const handleEditClick = () => {
    setSelectedEventType(event.type)
    setIsStartLiveDisabled(false)
    handleClose()
    redirect(`${pathname}/${event.id}`, [{ eventType: event?.type?.code }])
  }

  const handleDelete = () =>
    deleteEvent.mutate({
      eventTypeCode: event?.type?.code,
      eventId: clickedEventId,
    })

  const handleCloseEventClickModal = () => {
    setIsStartLiveDisabled(false)
    handleClose()
    setClickedEventId('')
    queryClient.removeQueries([QueryKeys.GET_GAME_STATS])
  }

  const handleDeleteIcon = () => setOpenDeleteModal(true)

  const handleDownloadIcon = () => {
    if (recording?.link && !recording?.preparing) {
      window.location.replace(recording?.link)
    } else if (recording?.preparing) {
      setPreparationAlert(true)
    }
  }

  const handleCopyIcon = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setShowCopyAlert(true)
      })
      .catch(() => console.error('Failed to write to clipboard:'))
  }

  const handlePracticePlanInfoClick = () => {
    redirect(
      `/practice-plan/${event?.practicePlan?.id}/show`,
      [{ team: teams?.[0]?.id }],
      ['goBack'],
    )
  }
  const handlePracticePlayersInfoClick = () => {
    redirect(
      `/practice-players/${practicePlayers.id}/show`,
      [{ eventId: event.id }, { team: teams?.[0]?.id }],
      ['goBack'],
    )
  }

  const handleWatchVideoClick = (eventId, broadcastId) =>
    redirect(`/livestream/${broadcastId}`, [{ eventId }])

  const handleGameStatsClick = (eventId) => {
    redirect(`/game/stats`, [{ eventId }])
  }

  const handleStartVideoClick = (broadcastId) => startLivestreamMutation.mutate(broadcastId)

  return (
    <Dialog
      id='calendar-event-dialog'
      open={open}
      onClose={handleCloseEventClickModal}
      fullWidth
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      classes={{ paper: classes.dialog }}
      disableScrollLock
    >
      <DialogContent>
        <div className={classes.dialogHeader}>
          <IsUserRolesAuthorized
            acceptedRoles={[
              USER_ORG_ROLES.OWNER,
              USER_ORG_ROLES.ADMIN,
              USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
            ]}
            searchIds={event ? event.teams.map((team) => team.id) : []}
          >
            <MyEditIcon onClick={handleEditClick} className={classes.orangeFill} />
            <MyDeleteIcon
              className={isDeleteIconDisabled ? classes.deleteIconDisabled : classes.orangeFill}
              disabled={isDeleteIconDisabled}
              onClick={handleDeleteIcon}
            />
            <DeleteModal
              title={translate('ra.text.deleteEventTitle')}
              subtitle={constructSubtitle()}
              open={openDeleteModal}
              onClose={() => setOpenDeleteModal(false)}
              handleDelete={handleDelete}
              loading={deleteEvent.isLoading}
            />
          </IsUserRolesAuthorized>
          <MyCloseIcon className={classes.orangeFill} onClick={handleCloseEventClickModal} />
        </div>

        <div className={classes.flexDivSpread}>
          <div className={classes.titleContainer}>
            <BoxEventTypeColor color={type?.color} xs={1} />
            <div className={classes.title}>{title}</div>
          </div>
          {allowWatchVideo && (
            <TextButton
              label={translate('ra.buttons.watchVideo')}
              onClick={() => handleWatchVideoClick(clickedEventId, broadcast?.id)}
            />
          )}
          {allowStartLive && (
            <TextButton
              label={translate('ra.buttons.startVideo')}
              onClick={() => setIsStartLiveModalOpen(true)}
              disabled={isStartLiveDisabled}
            />
          )}
        </div>

        <IsUserScopesAuthorized acceptedScopes={[USER_SCOPES.STATS]}>
          <ConditionalRender
            renderValue={
              type?.code === 'game' && gameStats?.teams.length > 0 && status === 'success'
            }
          >
            <div className={classes.flexDiv}>
              <BarChart />
              <span
                style={{ color: COLORS.darkGrey, cursor: 'pointer' }}
                onClick={() => handleGameStatsClick(clickedEventId)}
              >
                Game Stats
              </span>
            </div>
          </ConditionalRender>
        </IsUserScopesAuthorized>

        <div className={classes.flexDiv}>
          <CalendarIcon xs={1} />
          <RenderEventRange start={startDateTime} end={endDateTime} />
        </div>

        <div className={classes.flexDiv}>
          <MyGroupIcon xs={1} />
          <span>{teams?.map((team) => team.name).join(', ')}</span>
        </div>

        <ConditionalRender renderValue={notes}>
          <div className={classes.notes}>{notes}</div>
        </ConditionalRender>

        <IsUserScopesAuthorized acceptedScopes={[USER_SCOPES.LIVESTREAM]}>
          <ConditionalRender renderValue={hasRecording}>
            <div className={classes.flexDiv}>
              <DownloadIcon xs={1} />
              <span style={{ display: preparationAlert ? 'none' : 'inline' }}>
                <a style={{ cursor: 'pointer' }} onClick={handleDownloadIcon}>
                  {translate('ra.text.downloadRecording')}
                </a>
              </span>
              <MyCopyIcon
                style={{ display: preparationAlert || recording?.preparing ? 'none' : 'inline' }}
                onClick={() => {
                  setCopyRecordingClicked(true)
                  handleCopyIcon(recording?.link)
                }}
              />
              <ConditionalRender
                renderValue={showCopyAlert && !recording?.preparing && copyRecordingClicked}
              >
                <span className={classes.copyAlert}>{translate('ra.text.copied')}</span>
              </ConditionalRender>
              <ConditionalRender renderValue={preparationAlert && recording?.preparing}>
                <span className={classes.copyAlert}>{translate('ra.text.emailNotification')}</span>
              </ConditionalRender>
            </div>
          </ConditionalRender>

          <ConditionalRender renderValue={broadcastUrl}>
            <div className={classes.flexDiv}>
              <YouTubeIcon style={{ fill: COLORS.grey }} />
              <a href={broadcastUrl} target='_blank' rel='noopener noreferrer'>
                {broadcastUrl}
              </a>
              <MyCopyIcon onClick={() => handleCopyIcon(broadcastUrl)} />
              <ConditionalRender renderValue={showCopyAlert && !copyRecordingClicked}>
                <span className={classes.copyAlert}>{translate('ra.text.copied')}</span>
              </ConditionalRender>
            </div>
          </ConditionalRender>
        </IsUserScopesAuthorized>

        <PracticePlanInfoRow
          className={classes.practicePlanInfo}
          practicePlan={event?.practicePlan}
          onContainerClick={handlePracticePlanInfoClick}
        />

        <PracticePlayerInfoRow
          className={classes.practicePlanInfo}
          practicePlayers={practicePlayers}
          onContainerClick={handlePracticePlayersInfoClick}
        />
      </DialogContent>
      <StartLivestreamConfirmationModal
        isStartLiveModalOpen={isStartLiveModalOpen}
        handleCloseStartLiveModal={() => setIsStartLiveModalOpen(false)}
        handleModalStartButton={() => handleStartVideoClick(broadcast?.id)}
      />
    </Dialog>
  )
}

CalendarEventClickModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default CalendarEventClickModal
