import axios from 'axios'
import generalHeader from '../../utils/constants/generalHeader'
import { BASE_URL } from '../../utils/constants/url'

export const googleLogin = async (data) => {
  const url = `${BASE_URL}/auth/google`

  const response = await axios.post(url, data.credential, {
    headers: generalHeader(),
    withCredentials: true,
  })
  if (response.status !== 200) throw new Error(response.status)
  return response.data
}
