const googleLoginButtonStyles = {
  buttonContainer: {
    width: '100%',
  },
  googleButton: {
    width: '100%',
  },
}

export default googleLoginButtonStyles
