import DialogWithCloseIcon from '../../../../components/DialogWithCloseIcon/DialogWithCloseIcon'
import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import livestreamBannerStopConfirmationModalStyles from '../../../../components/LivestreamBannerStopConfirmationModal/livestreamBannerStopConfirmationModal.styles'
import { useTranslate } from 'react-admin'
import ButtonWithLoader from '../../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import COLORS from '../../../../utils/constants/colors'

const StartLivestreamConfirmationModal = (props) => {
  const { isStartLiveModalOpen, handleCloseStartLiveModal, handleModalStartButton, isLoading } =
    props
  const translate = useTranslate()
  const useStyles = makeStyles(livestreamBannerStopConfirmationModalStyles)
  const classes = useStyles()

  return (
    <DialogWithCloseIcon
      open={isStartLiveModalOpen}
      onClose={handleCloseStartLiveModal}
      dialogTitle={translate('ra.text.startLivestream')}
      dialogWidth='50%'
      maxDialogWidth='400'
    >
      <Grid
        className={classes.buttonsWrapper}
        container
        justifyContent='flex-end'
        alignItems='center'
      >
        <button className={classes.cancelButton} type='button' onClick={handleCloseStartLiveModal}>
          {translate('ra.buttons.cancel')}
        </button>
        <ButtonWithLoader
          style={{ backgroundColor: COLORS.red }}
          type='button'
          label={translate('ra.buttons.startVideo')}
          loading={isLoading}
          onClick={handleModalStartButton}
        />
      </Grid>
    </DialogWithCloseIcon>
  )
}

export default StartLivestreamConfirmationModal
