import COLORS from '../../utils/constants/colors'

const orgSeatManagementStyles = {
  onHover: {
    backgroundColor: COLORS.lightGrey,
    '&:hover': {
      backgroundColor: COLORS.orange,
    },
  },
  typographyBold: { fontWeight: 'bold' },
  priceText: {
    fontWeight: 'bold',
  },
}

export default orgSeatManagementStyles
