import React, { useEffect, useState } from 'react'
import { Divider, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import orgSeatManagementStyles from './orgSeatManagement.styles'
import AddButton from '../ui/AddButton/AddButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import COLORS from '../../utils/constants/colors'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'
import { useMutation, useQueryClient } from 'react-query'
import addEmptyOrgSeats from '../../Apis/subscriptions/addEmptyOrgSeats'
import useGetLocalStorage from '../../hooks/useGetLocalStorage'
import localStorageIds from '../../utils/constants/localStorageIds'
import getOrgQuoteMulti from '../../Apis/subscriptions/getOrgQuoteMulti'
import LoaderDots from '../loaders/LoaderDots/LoaderDots'
import QueryKeys from '../../utils/constants/queryKeys'

const OrgSeatManagement = (props) => {
  const { subDetails, isModal } = props
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const localStorageValue = useGetLocalStorage()
  const { [localStorageIds.SELECTED_ORG]: selectedOrg } = localStorageValue || {}
  const { id: orgId } = selectedOrg || {}
  const nonOrgLevelAddons = subDetails.addons.filter(
    (addon) => addon.isOrgLevel === false && addon.enabled,
  )
  const [addedSeats, setAddedSeats] = useState(
    nonOrgLevelAddons.reduce((acc, item) => {
      acc[item.id] = 0
      return acc
    }, {}),
  )
  const [pricing, setPricing] = useState([])
  const isActivationDisabled = !Object.values(addedSeats).some((seats) => seats > 0)
  const totalAmount = Object.values(pricing).reduce((sum, item) => sum + (item.amount || 0), 0)

  const useStyles = makeStyles(orgSeatManagementStyles)
  const classes = useStyles()

  const addOrgSeats = useMutation((data) => addEmptyOrgSeats(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_SUBSCRIPTION_DETAILS)
      queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME)

      setAddedSeats((prevState) => {
        const updatedState = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = 0
          return acc
        }, {})
        return updatedState
      })
    },
  })

  const pricingMutation = useMutation((data) => getOrgQuoteMulti(data), {
    onSuccess: (res) => {
      setPricing(res)
    },
  })

  useEffect(() => {
    pricingMutation.mutate({ orgId, addonSeats: addedSeats })
  }, [addedSeats])

  const addSeat = (AddonType) => {
    setAddedSeats({ ...addedSeats, [AddonType]: addedSeats[AddonType] + 1 })
  }
  const removeSeat = (AddonType) => {
    if (addedSeats[AddonType] > 0)
      setAddedSeats({ ...addedSeats, [AddonType]: addedSeats[AddonType] - 1 })
  }

  if (!subDetails.addons.some((addon) => addon.isOrgLevel === false)) return null
  return (
    <>
      {!isModal && (
        <Typography variant='h5' gutterBottom className={classes.typographyBold}>
          {translate('ra.text.orgSeats')}
        </Typography>
      )}
      {nonOrgLevelAddons.map((addon) => {
        const addonSeats = addon.seats

        return (
          <>
            <Grid
              style={{
                display: 'flex',
                paddingLeft: 10,
                marginTop: 20,
              }}
            >
              <Grid xs={2}>
                <Typography variant='h7' className={classes.typographyBold}>
                  {addon.id}
                </Typography>
              </Grid>
              <Grid xs={2} justifyContent='center' style={{ display: 'flex' }}>
                <Typography variant='h7'>{`${addonSeats.allocated}/${addonSeats.all}`}</Typography>
              </Grid>

              <Grid xs={5} justifyContent='center' style={{ display: 'flex', gap: 10 }}>
                {addon.pricingModel === 'PerSeat' && (
                  <>
                    <AddButton
                      style={{
                        width: 30,
                        minWidth: 7,
                        height: 25,
                        border: 'solid 1px',
                        borderColor: COLORS.subscriptionBlue,
                      }}
                      icon={<RemoveCircleIcon style={{ fill: COLORS.subscriptionBlue }} />}
                      onClick={() => removeSeat(addon.id)}
                    />
                    <Typography>{`${addedSeats[addon.id]} added seats`}</Typography>
                    <AddButton
                      filled
                      style={{
                        backgroundColor: COLORS.subscriptionBlue,
                        width: 30,
                        minWidth: 7,
                        height: 25,
                      }}
                      onClick={() => addSeat(addon.id)}
                    />
                  </>
                )}
              </Grid>
              <Grid xs={4} justifyContent='center' alignItems='center' style={{ display: 'flex' }}>
                <Typography>
                  {addon.pricingModel === 'PerSeat' ? `€${addon.unitPrice}/seat/month` : '-'}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </>
        )
      })}
      <div
        style={{
          paddingLeft: 10,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 20,
          marginTop: 10,
        }}
      >
        {pricingMutation.isLoading ? (
          <LoaderDots />
        ) : (
          <Tooltip
            title='The price takes into account trial period, prorating, and discounting.'
            arrow
          >
            <Typography className={classes.priceText}>{`Total: €${totalAmount}`}</Typography>
          </Tooltip>
        )}

        <ButtonWithLoader
          label='Purchase Seats'
          style={{
            backgroundColor: !isActivationDisabled ? COLORS.subscriptionBlue : COLORS.searchGrey,
          }}
          loading={addedSeats.isLoading}
          disabled={isActivationDisabled}
          onClick={() => addOrgSeats.mutate({ orgId, addedSeats })}
        />
      </div>
    </>
  )
}

export default OrgSeatManagement
