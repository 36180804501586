import React from 'react'
import { useTranslate } from 'react-admin'
import { useMutation, useQueryClient } from 'react-query'
import { Grid } from '@material-ui/core'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import DialogWithCloseIcon from '../../DialogWithCloseIcon/DialogWithCloseIcon'
import MyTextField from '../../form/fields/MyTextField'
import TextButton from '../TextButton/TextButton'
import ButtonWithLoader from '../ButtonWithLoader/ButtonWithLoader'
import addCourt from '../../../Apis/courts/addCourt'
import QueryKeys from '../../../utils/constants/queryKeys'
import ContainerPadding from '../../containerPadding/containerPadding'
import useQueryParams from '../../../hooks/useQueryParams'

const AddCourtModal = (props) => {
  const { isCourtModalOpen, setIsCourtModalOpen, setValue } = props
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const queryParams = useQueryParams()

  const onClose = () => {
    setIsCourtModalOpen(false)
  }

  const createCourt = useMutation((data) => addCourt(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueryKeys.GET_COURTS)
      setValue(data.id)
      onClose()
    },
  })

  const handleSubmit = (formValues) => {
    createCourt.mutate({
      orgIds: [queryParams?.org],
      ...formValues,
    })
  }

  return (
    <DialogWithCloseIcon
      open={isCourtModalOpen}
      onClose={onClose}
      dialogTitle={translate('ra.text.createNewCourt')}
      dialogWidth='20%'
    >
      <ContainerPadding>
        <Formik
          initialValues={{
            name: '',
            description: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ dirty }) => (
            <Form>
              <Grid container alignItems='center' spacing={2} style={{ paddingBottom: 10 }}>
                <Grid item xs={12}>
                  <MyTextField
                    isFullWidth
                    label={translate('ra.formFields.name')}
                    name='name'
                    variant='filled'
                  />
                </Grid>

                <Grid item xs={12}>
                  <MyTextField
                    isFullWidth
                    label={translate('ra.text.description')}
                    name='description'
                    variant='filled'
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
                <Grid item>
                  <TextButton onClick={onClose} label={translate('ra.buttons.cancel')} />
                </Grid>

                <Grid item>
                  <ButtonWithLoader
                    label={translate('ra.buttons.add')}
                    disabled={!dirty}
                    loading={createCourt.isLoading}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ContainerPadding>
    </DialogWithCloseIcon>
  )
}

AddCourtModal.propTypes = {
  isCourtModalOpen: PropTypes.bool,
  setIsCourtModalOpen: PropTypes.func,
  setValue: PropTypes.func,
}

export default AddCourtModal
