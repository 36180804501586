import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import LivestreamBanner from '../LivestreamBanner/LivestreamBanner'
import QueryKeys from '../../utils/constants/queryKeys'
import getRunningBroadcastEvents from '../../Apis/event/getRunningBroadcastEvents'
import useQueryParams from '../../hooks/useQueryParams'
import localStorageIds from '../../utils/constants/localStorageIds'

const LivestreamBannerContainer = () => {
  const [connection, setConnection] = useState(null)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(localStorage.getItem(localStorageIds.TOKEN))
  const queryParams = useQueryParams()
  const queryClient = useQueryClient()
  const { org } = queryParams

  useEffect(() => {
    const onStorage = () => {
      setIsUserLoggedIn(localStorage.getItem(localStorageIds.TOKEN))
    }

    window.addEventListener('storage', onStorage)

    return () => {
      window.removeEventListener('storage', onStorage)
    }
  }, [])

  // useQuery(
  //   QueryKeys.GET_LIVESTREAM_HEALTH,
  //   () => getLivestreamHealth(broadcastId),
  //   {
  //     enabled: broadcastId?.length > 0,
  //     onSuccess: () => {
  //       setLivestreamOpen(true)
  //     }
  //   }
  // );

  const broadcastEvents = useQuery(
    QueryKeys.GET_RUNNING_BROADCASTS_EVENTS,
    () => org && getRunningBroadcastEvents(org),
    {
      refetchInterval: 10000,
      enabled: Boolean(isUserLoggedIn),
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_EVENT).then(() => {})
      },
    },
  )

  return broadcastEvents?.data
    ? broadcastEvents.data?.map((broadcastEvent, index) => (
        <LivestreamBanner
          key={broadcastEvent?.id}
          broadcastEvent={broadcastEvent}
          bannerIndex={index}
        />
      ))
    : null
}

export default LivestreamBannerContainer
