import React from 'react'
import { useTranslate } from 'react-admin'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import DialogWithCloseIcon from '../DialogWithCloseIcon/DialogWithCloseIcon'
import livestreamBannerStopConfirmationModalStyles from './livestreamBannerStopConfirmationModal.styles'

const LivestreamBannerStopConfirmationModal = (props) => {
  const { isStopModalOpen, handleCloseStopModal, handleModalStopButton } = props
  const translate = useTranslate()

  const useStyles = makeStyles(livestreamBannerStopConfirmationModalStyles)
  const classes = useStyles()

  return (
    <DialogWithCloseIcon
      open={isStopModalOpen}
      onClose={handleCloseStopModal}
      dialogTitle={translate('ra.text.stopLivestream')}
      dialogWidth='50%'
      maxDialogWidth='400'
    >
      <Grid
        className={classes.buttonsWrapper}
        container
        justifyContent='flex-end'
        alignItems='center'
      >
        <button className={classes.cancelButton} type='button' onClick={handleCloseStopModal}>
          {translate('ra.buttons.cancel')}
        </button>
        <button className={classes.stopButton} type='button' onClick={handleModalStopButton}>
          {translate('ra.buttons.stop')}
        </button>
      </Grid>
    </DialogWithCloseIcon>
  )
}

LivestreamBannerStopConfirmationModal.propTypes = {
  isStopModalOpen: PropTypes.bool,
  handleCloseStopModal: PropTypes.func,
  handleModalStopButton: PropTypes.func,
}

export default LivestreamBannerStopConfirmationModal
