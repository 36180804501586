import React from 'react'
import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-admin'
import CheckIcon from '@material-ui/icons/Check'
import subscriptionAddonCardStyles from './subscriptionAddonCardStyles'
import COLORS from '../../../utils/constants/colors'
import TextButton from '../../../components/ui/TextButton/TextButton'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import addonInfo from '../../../utils/constants/addonInfo'
import useGetLocalStorage from '../../../hooks/useGetLocalStorage'
import localStorageIds from '../../../utils/constants/localStorageIds'
import subscriptionIconMapper from '../../../utils/constants/subscriptionMapper'

const SubscriptionAddonCard = ({
  handleActivateAddon,
  handleDeactivateAddon,
  addon,
  nonActivatableAddons,
  isAddonConfirmation,
}) => {
  const { id, pricingModel, unitPrice, enabled, userActivatable, seats, isOrgLevel } = addon || {}
  const translate = useTranslate()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1281))
  const useStyles = makeStyles(subscriptionAddonCardStyles(enabled))
  const classes = useStyles()
  const localStorageValue = useGetLocalStorage()
  const { [localStorageIds.SELECTED_ORG]: selectedOrg } = localStorageValue || {}

  const mapPricingModel = (pricingModel, unitPrice) => {
    const pricingModels = {
      PerSeat: 'Seat',
      Manual: 'Offline Payment',
      Fixed: 'Month',
    }

    if (unitPrice) {
      return `€${unitPrice}/${pricingModels[pricingModel]}`
    }

    return `${pricingModels[pricingModel]}`
  }

  const buttonProps = {
    className: classes.button,
    style: {
      height: '25px',
      backgroundColor: COLORS.opacBlue,
      border: `1px solid ${COLORS.subscriptionBlue}`,
      color: COLORS.subscriptionBlue,
      fontSize: isSmallScreen ? '10px' : '12px',
    },
  }

  const isButtonDisabled =
    nonActivatableAddons ||
    (id === 'Livestream' && enabled) ||
    (id === 'Engage' && enabled) ||
    (id === 'Coach' && selectedOrg?.type === 'League')

  const getCardButton = () => {
    if (isButtonDisabled) return null

    if (enabled && userActivatable)
      return (
        <TextButton
          className={classes.deactivateBtn}
          style={{ fontSize: isSmallScreen ? '10px' : '12px' }}
          label={translate('ra.buttons.deactivate')}
          onClick={() => handleDeactivateAddon(addon)}
        />
      )

    if (userActivatable)
      return (
        <TextButton
          {...buttonProps}
          label={translate('ra.buttons.activate')}
          onClick={() => handleActivateAddon(addon)}
        />
      )

    return (
      <TextButton
        {...buttonProps}
        label={translate('ra.buttons.contactUs')}
        onClick={() => window.open('https://www.mismatch.gr/contact/', '_blank')}
      />
    )
  }

  return (
    <Card variant='outlined' elevation={0} className={classes.planCard}>
      <CardContent className={classes.cardContent}>
        <Grid
          item
          sm={nonActivatableAddons ? 4 : 2}
          style={{
            display: 'flex',
            justifyContent: isSmallScreen ? 'center' : 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: isSmallScreen ? 'center' : '',
              }}
            >
              {subscriptionIconMapper[id]}
            </div>
            <Typography
              variant='h6'
              style={{
                fontWeight: 'bold',
                marginLeft: 5,
                fontSize: isSmallScreen ? '14px' : '20px',
              }}
            >
              {id === 'Coach' ? 'Coaching' : id}
            </Typography>
          </div>
        </Grid>
        {isButtonDisabled ? (
          <Grid
            item
            sm={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {addonInfo[id].map((feature) => (
              <List key={feature} className={classes.dotText}>
                <ListItemIcon style={{ minWidth: 20 }}>
                  <CheckIcon className={classes.check} />
                </ListItemIcon>
                <ListItemText
                  primary={feature}
                  primaryTypographyProps={{ style: { fontSize: isSmallScreen ? '12px' : '16px' } }}
                />
              </List>
            ))}
          </Grid>
        ) : (
          <Grid
            item
            sm={nonActivatableAddons ? 6 : 4}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {addonInfo[id].map((feature) => (
              <List key={feature} className={classes.dotText}>
                <ListItemIcon style={{ minWidth: 20 }}>
                  <CheckIcon className={classes.check} />
                </ListItemIcon>
                <ListItemText
                  primary={feature}
                  primaryTypographyProps={{ style: { fontSize: isSmallScreen ? '12px' : '16px' } }}
                />
              </List>
            ))}
          </Grid>
        )}

        <Grid
          item
          sm={isSmallScreen ? 4 : 2}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <ConditionalRender
            renderValue={handleActivateAddon && handleDeactivateAddon && !nonActivatableAddons}
          >
            <Typography variant='body1' style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>
              {userActivatable && enabled && !isOrgLevel
                ? `Seats: ${seats?.allocated || 0}/${seats?.all || 0}`
                : ''}
            </Typography>
          </ConditionalRender>
          {!nonActivatableAddons && isSmallScreen && (
            <ConditionalRender renderValue={handleActivateAddon && handleDeactivateAddon}>
              <Typography variant='body1' style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>
                {userActivatable
                  ? mapPricingModel(pricingModel, unitPrice)
                  : mapPricingModel(pricingModel)}
              </Typography>
            </ConditionalRender>
          )}
        </Grid>
        {!isAddonConfirmation ? (
          <>
            {!nonActivatableAddons && !isSmallScreen && (
              <Grid
                item
                sm={2}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <ConditionalRender renderValue={handleActivateAddon && handleDeactivateAddon}>
                  <Typography variant='body1' style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>
                    {userActivatable
                      ? mapPricingModel(pricingModel, unitPrice)
                      : mapPricingModel(pricingModel)}
                  </Typography>
                </ConditionalRender>
              </Grid>
            )}

            <Grid
              item
              sm={nonActivatableAddons ? 4 : 2}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <div style={{ display: 'flex', justifyContent: 'center', width: '80%' }}>
                {!nonActivatableAddons ? (
                  getCardButton()
                ) : id === 'Livestream' ? (
                  <List className={classes.priceColumn}>
                    <ListItemText
                      primary={
                        <TextButton
                          label={translate('ra.buttons.contactUs')}
                          className={classes.button}
                          style={{
                            height: '25px',
                            fontSize: isSmallScreen ? '10px' : '12px',
                            backgroundColor: COLORS.opacBlue,
                            border: `1px solid ${COLORS.subscriptionBlue}`,
                            color: COLORS.subscriptionBlue,
                          }}
                          onClick={() => window.open('https://www.mismatch.gr/contact/', '_blank')}
                        />
                      }
                    />
                  </List>
                ) : (
                  <List className={classes.priceColumn}>
                    <ListItemText primary={userActivatable ? `15 Days Trial` : 'Contact Us'} />
                    <ListItemText
                      primary={
                        userActivatable ? (
                          <Typography
                            style={{
                              textDecoration: 'line-through',
                              marginRight: 5,
                              fontSize: isSmallScreen ? '12px' : '16px',
                            }}
                            primaryTypographyProps={{
                              style: { fontSize: isSmallScreen ? '12px' : '16px' },
                            }}
                          >
                            {mapPricingModel(pricingModel, unitPrice)}
                          </Typography>
                        ) : (
                          ''
                        )
                      }
                    />
                  </List>
                )}
              </div>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sm={2}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <ConditionalRender renderValue={handleActivateAddon && handleDeactivateAddon}>
              <Typography variant='body1' style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>
                {userActivatable
                  ? mapPricingModel(pricingModel, unitPrice)
                  : mapPricingModel(pricingModel)}
              </Typography>
            </ConditionalRender>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

SubscriptionAddonCard.propTypes = {
  handleActivateAddon: PropTypes.func,
  handleDeactivateAddon: PropTypes.func,
  addon: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pricing: PropTypes.string,
    seats: PropTypes.shape({
      all: PropTypes.number,
      allocated: PropTypes.number,
      free: PropTypes.number,
    }),
    enabled: PropTypes.bool.isRequired,
    userActivatable: PropTypes.bool.isRequired,
  }),
  nonActivatableAddons: PropTypes.bool,
  isAddonConfirmation: PropTypes.bool,
}

SubscriptionAddonCard.defaultProps = {
  handleActivateAddon: () => {},
  handleDeactivateAddon: () => {},
  addon: null,
  nonActivatableAddons: null,
  isAddonConfirmation: false,
}

export default SubscriptionAddonCard
