import React, { useRef } from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { Field, useFormikContext } from 'formik'
import ImageCropWithEdit from '../../../../ui/ImageCropComponents/ImageCropWithEdit/ImageCropWithEdit'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../../../../utils/constants/gameEventFormBroadcastFields'
import { isTeamFieldsAndCompDisabled } from '../../../../../utils/helpers/isFieldDisabled'
import emptyOrg from '../../../../../assets/images/org_empty.svg'
import MyTextField from '../../../fields/MyTextField'
import CROPPER_SHAPES from '../../../../../utils/constants/cropperShapes'
import CourtLogosPreviewEditBox from '../../../../CourtLogosPreviewEditBox/CourtLogosPreviewEditBox'
import eventFormCourtLogosStyles from '../../../../EventFormCourtLogos/eventFormCourtLogos.styles'
import useResize from '../../../../../hooks/useResize'
import ORGANISATION_TYPES from '../../../../../utils/constants/OrganisationTypes'
import MyAutocomplete from '../../../fields/MyAutocomplete'
import SelectRenderValue from '../../../../SelectRenderValue/SelectRenderValue'

const EventTeamsFields = ({ state, orgType, teams, preFilledHome, preFilledAway }) => {
  const { values, setFieldValue } = useFormikContext()
  const translate = useTranslate()
  const imageRef = useRef(null)
  const { width } = useResize(imageRef)
  const useStyles = makeStyles(
    eventFormCourtLogosStyles(width, values[GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_CENTER_LOGO]),
  )
  const classes = useStyles()
  const EDIT_PREVIEW_WIDTH = 60

  const setCroppedImage = (name, value) => setFieldValue(name, value)

  const changeTeamField = (e, value, nameField, logoField) => {
    if (nameField === GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME)
      setFieldValue('teams', [value, values.teams?.[1] || null])
    if (nameField === GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY)
      setFieldValue('teams', [values.teams?.[0] || null, value])

    setFieldValue(logoField, value?.teamLogo || null)
    setFieldValue(nameField, value || null)
  }

  return (
    <>
      <Divider className={classes.divider} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <div className='div-flex'>
          <ImageCropWithEdit
            defaultImageSrc={values[GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME_LOGO]}
            setCroppedImage={(croppedImage) =>
              setCroppedImage(GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME_LOGO, croppedImage)
            }
            editPreviewWidth={EDIT_PREVIEW_WIDTH}
            dialogTitle='Edit your Home Team Logo'
            isDisabled={
              isTeamFieldsAndCompDisabled(state) || orgType === ORGANISATION_TYPES.ORG_LEAGUE
            }
            emptyPreviewIcon={emptyOrg}
          />
          {orgType === ORGANISATION_TYPES.ORG_CLUB ? (
            <MyTextField
              name={GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME}
              label={translate('ra.formFields.homeTeam')}
              required
              disabled={isTeamFieldsAndCompDisabled(state)}
              preFilled={preFilledHome}
            />
          ) : (
            <Field
              name={GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME}
              defaultValue={teams.find(
                (team) => team?.id === values[GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME]?.teamId,
              )}
              label={translate('ra.formFields.homeTeam')}
              style={{ width: '100%' }}
              required
              options={teams?.filter(
                (team) =>
                  values[GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY]?.teamId !==
                  (team?.teamId || team?.id),
              )}
              component={MyAutocomplete}
              autoSelect={false}
              onChange={(e, value) =>
                changeTeamField(
                  e,
                  value,
                  GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME,
                  GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME_LOGO,
                )
              }
              getOptionLabel={(option) => option.name}
              renderOption={(option) => {
                return (
                  <SelectRenderValue
                    item={option}
                    chosenOption={values.teams || {}}
                    logoArgName='teamLogo'
                    labelBlack
                  />
                )
              }}
            />
          )}
        </div>

        <div className='div-flex'>
          <ImageCropWithEdit
            defaultImageSrc={values[GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY_LOGO]}
            setCroppedImage={(croppedImage) =>
              setCroppedImage(GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY_LOGO, croppedImage)
            }
            editPreviewWidth={EDIT_PREVIEW_WIDTH}
            dialogTitle='Edit your Away Team Logo'
            isDisabled={
              isTeamFieldsAndCompDisabled(state) || orgType === ORGANISATION_TYPES.ORG_LEAGUE
            }
            emptyPreviewIcon={emptyOrg}
          />
          {orgType === ORGANISATION_TYPES.ORG_CLUB ? (
            <MyTextField
              name={GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY}
              label={translate('ra.formFields.awayTeam')}
              required
              disabled={isTeamFieldsAndCompDisabled(state)}
              preFilled={preFilledAway}
            />
          ) : (
            <Field
              name={GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY}
              defaultValue={teams.find(
                (team) => team?.id === values[GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY]?.teamId,
              )}
              label={translate('ra.formFields.awayTeam')}
              style={{ width: '100%' }}
              required
              options={teams?.filter(
                (team) =>
                  values[GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME]?.teamId !==
                  (team?.teamId || team?.id),
              )}
              autoSelect={false}
              component={MyAutocomplete}
              onChange={(e, value) =>
                changeTeamField(
                  e,
                  value,
                  GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY,
                  GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY_LOGO,
                )
              }
              getOptionLabel={(option) => option.name}
              renderOption={(option) => {
                return (
                  <SelectRenderValue
                    item={option}
                    chosenOption={values.teams || {}}
                    logoArgName='teamLogo'
                    labelBlack
                  />
                )
              }}
            />
          )}
        </div>

        <div className={classes.competitionFieldsWrapper}>
          <ImageCropWithEdit
            defaultImageSrc={values[GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_COMPETITION_LOGO]}
            setCroppedImage={(croppedImage) =>
              setCroppedImage(GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_COMPETITION_LOGO, croppedImage)
            }
            aspect={2.17287234}
            minZoom={0.2}
            cropShape={CROPPER_SHAPES.RECT}
            dialogBottomText='Proposed Width: 300-1500 pixels'
            isDisabled={isTeamFieldsAndCompDisabled(state)}
            EditPreviewComponent={CourtLogosPreviewEditBox}
            editPreviewComponentClassName={
              values[GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_COMPETITION_LOGO]
                ? classes.competitionLogoFilled
                : classes.competitionLogo
            }
          />

          <MyTextField
            name={GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_COMPETITION_NAME}
            label='Competition Name'
            isFullWidth={false}
            disabled={isTeamFieldsAndCompDisabled(state)}
          />
        </div>
      </div>
    </>
  )
}

export default EventTeamsFields
