import { useTranslate } from 'react-admin'
import { isToday } from 'date-fns'
import isTimePast from '../utils/helpers/dateHelpers/isTimePast'
import isTimeDifferenceAboveCertainDuration from '../utils/helpers/dateHelpers/isTimeDifferenceAboveCertainDuration'
import ORCHESTRATOR_LIVESTREAM_MINIMUM_DURATION from '../utils/constants/orchestratorLivestreamMinimumDuration'
import isTimeBefore from '../utils/helpers/dateHelpers/isTimeBefore'
import isDateBefore from '../utils/helpers/dateHelpers/isDateBefore'
import isObjectEmptyHelper from '../utils/helpers/isObjectEmptyHelper'
import LIVESTREAM_TYPE_OPTIONS from '../utils/constants/livestreamTypeOptions'

const useEventFormsValidations = (formName, broadcastId, hasSetYoutube, useOrgYoutube) => {
  const translate = useTranslate()

  // TODO Add mapper or fix structure.
  const createLivestreamEventValidations = (values) => {
    const {
      isBroadcast,
      fromDate,
      startTime,
      endTime,
      place,
      courtId,
      teams,
      type,
      youtubeStreamKey,
      homeTeam,
      awayTeam,
    } = values || {}

    const errorsCreate = {}

    if (isObjectEmptyHelper(teams)) {
      errorsCreate.teams = translate('ra.validationErrors.requiredField')
    }

    if (isBroadcast) {
      if (isDateBefore(new Date(fromDate))) {
        errorsCreate.fromDate = translate('ra.validationErrors.fromDate')
      }

      if (isToday(new Date(fromDate)) && isTimePast(startTime)) {
        errorsCreate.startTime = translate('ra.validationErrors.startTime')
      }

      if (
        isTimeBefore(endTime, startTime) ||
        !isTimeDifferenceAboveCertainDuration(
          startTime,
          endTime,
          ORCHESTRATOR_LIVESTREAM_MINIMUM_DURATION,
        )
      ) {
        errorsCreate.endTime = translate('ra.validationErrors.endTime')
      }

      if (formName === 'game' && !courtId) {
        errorsCreate.courtId = translate('ra.validationErrors.courtId')
      }

      if (formName === 'practice' && place === 'Court' && !courtId) {
        errorsCreate.courtId = translate('ra.validationErrors.courtId')
      }
      if (!hasSetYoutube && !useOrgYoutube && (!youtubeStreamKey || youtubeStreamKey.length < 1)) {
        errorsCreate.youtubeStreamKey = translate('ra.validationErrors.requiredField')
      }
      if (type === LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL && !homeTeam?.name) {
        errorsCreate.homeTeam = translate('ra.validationErrors.requiredField')
      }
      if (type === LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL && !awayTeam?.name) {
        errorsCreate.awayTeam = translate('ra.validationErrors.requiredField')
      }
    }

    return errorsCreate
  }

  const editLivestreamEventValidations = (values) => {
    const { isBroadcast, startTime, endTime, teams, type, youtubeStreamKey, awayTeam, homeTeam } =
      values || {}
    let errorsEdit = {}

    if (isObjectEmptyHelper(teams)) {
      errorsEdit.teams = translate('ra.validationErrors.requiredField')
    }

    if (isBroadcast) {
      if (!broadcastId) {
        errorsEdit = createLivestreamEventValidations(values)
      } else if (
        isTimeBefore(endTime, startTime) ||
        !isTimeDifferenceAboveCertainDuration(
          startTime,
          endTime,
          ORCHESTRATOR_LIVESTREAM_MINIMUM_DURATION,
        )
      ) {
        errorsEdit.endTime = translate('ra.validationErrors.endTime')
      }
      if (!hasSetYoutube && !useOrgYoutube && (!youtubeStreamKey || youtubeStreamKey.length < 1)) {
        errorsEdit.youtubeStreamKey = translate('ra.validationErrors.requiredField')
      }
      if (type === LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL && !homeTeam?.name) {
        errorsEdit.homeTeam = translate('ra.validationErrors.requiredField')
      }
      if (type === LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL && !awayTeam?.name) {
        errorsEdit.awayTeam = translate('ra.validationErrors.requiredField')
      }
    }

    return errorsEdit
  }

  return { createLivestreamEventValidations, editLivestreamEventValidations }
}

export default useEventFormsValidations
